import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const ContactUs = () => {
  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [email, setEmail] = useState();
  const [desc, setDesc] = useState();

  const handleSubmit = event => {
    event.preventDefault();
    const recipeUrl = "https://lakhina-mortgages.herokuapp.com/email/"; // api url for sending the email
    const postBody = {
      name,
      subject,
      email,
      text: desc
    };

    const notify = () =>
      toast.success("Messgae send Successfully!", {
        position: toast.POSITION.TOP_CENTER
      });

    const requestMetadata = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => res.json())
      .then(res1 => {
        document.getElementById("submit-request-form").reset();
        notify();
      });
  };

  return (
    <div
      className="container-fluid flex w-100"
      style={{ backgroundColor: "#F7F7F7" }}>
      <div className="flex-1 pt-5 plr-10 text-grey">
        <h3
          className="font-weight-bold"
          style={{ color: "#526E28", textTransform: "uppercase" }}>
          Contact Us Today
        </h3>
        <p>
          Feel free to contact us anytime with any questions that you have. We
          are here to help every step of the way!
        </p>

        <h3
          className="font-weight-bold"
          style={{ color: "#526E28", textTransform: "uppercase" }}>
          Address:
        </h3>
        <ul id="contact-us-list">
          <li>2672 Slough Street</li>
          <li>Mississauga, Ontario Canada L4T 1G3</li>
          <li>Phone No: +1 647-309-4608</li>
          <li>Email: lakhina.jas@gmail.com</li>
        </ul>
      </div>
      <div className=" flex-1 container pb-5" style={{ paddingTop: "40px" }}>
        <form
          id="submit-request-form"
          onSubmit={e => {
            handleSubmit(e);
          }}
          className="px-lg-5 pb-4">
          <div className="form-group">
            <label htmlFor="Name">Name</label>
            <input
              type="text"
              required={true}
              className="form-control py-4"
              id="Name"
              aria-describedby="emailHelp"
              placeholder="Enter you name"
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Email">Email</label>
            <input
              type="email"
              required={true}
              className="form-control py-4"
              id="Email"
              placeholder="Enter your email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Subject">Subject</label>
            <input
              type="text"
              required={true}
              className="form-control py-4"
              id="Subject"
              placeholder="Type the subject"
              onChange={e => setSubject(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Textarea">Message</label>
            <textarea
              className="form-control"
              required={true}
              id="Textarea"
              rows="3"
              onChange={e => setDesc(e.target.value)}
              placeholder="Type your message here..."></textarea>
          </div>

          <button type="submit" className="btn-book float-right">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
