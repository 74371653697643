import React from "react";
import Aboutus from "../images/Aboutus.jpg";
import Familyplan from "../images/loanPage/3.jpg";
import CallUs from "../images/loanPage/2.jpg";
import "../css/style.css";
import { hover } from "@testing-library/user-event/dist/hover";
import bad from "../images/bad.png";
import about from "../images/about-us-banner.webp";
const Service = () => {
  return (
    <>
      <div>
        <h1
          className="aboutus-Lakhina"
          style={{ color: "#526E28", fontWeight: "700" }}>
          Why us?
        </h1>
        <p className="aboutus-Lakhina-para">
          As Mortgage Professionals, we strive to provide you with the service
          that helps you to achieve your financial goals.
          <br /> <br />
          Whether you’re looking for a 1st, 2nd or 3rd mortgage, home equity
          line of credit to pay off debt or renovate your home or just simply
          renewing your mortgage. Even if you’re self-employed, a newcomer to
          Canada or have bruised credit, there is a mortgage product out there
          for you and with access to over 60 lenders, we will find the product
          that best suits your needs. “Our mission is to provide my clients with
          mortgage solutions that will assist them in realizing their long-term
          financial goals.”
          <br /> <br />
          We are a registered franchise of the Mortgage Alliance Network, and we
          work with over 70 lenders (some offered exclusively through brokers).
          We have 17 years of industry experience behind us, helping thousands
          of families like yours. When we work with new clients we provide you
          with credentials and references from our current and past clients to
          help you decide if we are the right brokerage for you.
        </p>
      </div>
      <div className="align-center service-container-reverse flex w-100">
        <div className="flex-1 pt-5 plr-10 text-grey">
          <h2
            className="font-weight-bold"
            style={{ color: "#526E28", fontWeight: "700" }}>
            Our Mission
          </h2>
          <p>
            Our team, strives to work towards fulfilling your financial needs in
            the best way possible. We do not work for the bank, we work for you.
            When you choose us for your mortgage needs, you can rest assured
            knowing you will be provided with the best solutions to your
            benefit. With us, you get:
          </p>
          <div>
            <ul id="contact-us-list">
              <i
                aria-hidden="true"
                style={{ color: "#004B8E" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text "
                style={{ paddingLeft: "10px" }}>
                Fast approval{" "}
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}>
                We work with all credit situations
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}>
                Affordable monthly payments
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}>
                We work with all income situations{" "}
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}>
                Toughest deals approved
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}>
                We work with all credit situations{" "}
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}>
                Maximum savings
              </span>
            </ul>
          </div>
        </div>
        <div className="flex-1 container pb-5" style={{ paddingTop: "70px" }}>
          <img style={{ height: "100%", width: "100%" }} src={Aboutus} alt="" />
          <div className="container text-center pb-3"></div>
        </div>
      </div>
      <div className="align-center service-container flex w-100">
        <div className=" flex-1 container pb-5" style={{ paddingTop: "70px" }}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={Familyplan}
            alt=""
            className="mobile-image"
          />
          <div className="container text-center pb-3"></div>
        </div>
        <div className="flex-1 pt-5 plr-10 text-grey">
          <h2
            className="font-weight-bold"
            style={{ color: "#526E28", fontWeight: "700" }}>
            Helping Clients in Ontario
          </h2>
          <p>
            Our team provide various mortgage service to a wide range of
            clients.Unlike banks, we care about about situation and providr you
            best service to get your mortgage done. Your appliaction will be
            sent to the various lenders so that you can be avoid going to the
            different lenders an d hassle to find the best mortgage for you. You
            would be able to glad to know that you can reach to our team over
            the phone anytime, just to make sure you are not left in the dark.
            Book your free consultation today with us.
          </p>
        </div>
      </div>
      <div className="align-center service-container-reverse  flex w-100">
        <div className="flex-1 pt-5 plr-10 text-grey">
          <h2
            className="font-weight-bold"
            style={{ color: "#526E28", fontWeight: "700" }}>
            Call Us before you sign
          </h2>
          <p>
            Already have a mortgage offer from another financial institution?
            Give us a call today and see how your existing mortgage renewal,
            approval or pre-approval compares to what you could get with Lakhina
            Mortgage in minutes.
            <br />
            Our team of expert Mortgage Advisor are here to help you find out if
            you could get a better deal.
            <br />
            Call us: +1 647-309-4608 <br />
            to talk to the Mortgage Advisor.
          </p>
          <div className="link">
            <a
              style={{
                color: "#526E28",
                fontWeight: "700",
                backgroundColor: "transparent",
                textDecoration: "underline",
                textDecoration: "uppercase",
                fontWeight: "700",
                lineHeight: "1.5rem",
                textTransform: "uppercase"
              }}
              className="claa-us-todays"
              href="tel:+1 647-309-4608">
              Call Us Today
            </a>
          </div>
        </div>
        <div className=" flex-1 container pb-5" style={{ paddingTop: "70px" }}>
          <img style={{ height: "100%", width: "100%" }} src={CallUs} alt="" />
          <div className="container text-center pb-3"></div>
        </div>
      </div>
    </>
  );
};

export default Service;
