import React from "react";
import '../css/style.css'
const Footer = () => {
  return (
    <div
      className="footer-container w-100"
      style={{ backgroundColor: "#bf9f00" }}
    >
      <div className="flex-1 pt-5 plr-10 text-grey">
        <h2
          className="font-weight-bold footer"
          style={{ color: '#526E28', fontWeight: '700' }}
        >
          Lakhina Mortgages Inc
        </h2>
        <h3 className="font-weight-bold " style={{ color: '#526E28', fontWeight: '700' }}>
          Hours of Operation
        </h3>
        <p style={{ color: '#526E28', fontWeight: '700' }}>
          Monday - Saturday: 9:00AM - 6:00PM <br />
          Sunday: Closed
        </p>
        <ul style={{ color: '#526E28' , fontWeight: '700' }} id="contact-us-list">
          <li>2672 Slough Street</li>
          <li>Mississauga, Ontario Canada L4T 1G3</li>
          <li>Phone No: +1 647-309-4608</li>
          <li>Email: lakhina.jas@gmail.com</li>
        </ul>
      </div>

      <div className="flex-1 container pb-5" style={{ paddingTop: "50px" }}>
        <h2 style={{color: '#526E28',fontWeight: '700', paddingBottom: '20px', textAlign: 'center'}}>Follow Lakhina Mortgages:</h2>
        <div className="container text-center pb-3">
          {/* <p className="pt-4 pb-3 small">416-464-3200</p> */}
          <ul className="social mb-0 list-inline mt-3">
            <li className="list-inline-item m-0">
              <a
                className="btn btn-primary"
                style={{ background: "#3B5998", color: "white" }}
                role="button"
                href="https://www.facebook.com/lakhinamtgs/"
                className="social-link"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li className="list-inline-item m-0">
              <a
                className="btn btn-primary social-link"
                style={{ background: "#c32aa3", color: "white" }}
                role="button"
                href="https://instagram.com/lakhinamtgs?utm_medium=copy_link"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li className="list-inline-item m-0">
              <a
                className="btn btn-primary social-link"
                style={{ background: "#007bb5", color: "white" }}
                href="https://www.linkedin.com/in/jaspreet-lakhina-988abb20"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <p className="pt-5 small" style={{ color: '#526E28' }}>
         Some Conditions May Apply. Call Us For Full Details | Copyright 2022 by LAKHINA MORTGAGES.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
