import React from "react";

// routing
import { Routes, Route, BrowserRouter } from "react-router-dom";

// css
import "./css/style.css"

// component
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./component/Home";
import Loans from "./component/Loans";
import Services from "./component/Services";
import Blog from "./component/Blog";


function App() {
  return (
    <div className="App w-100">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="loans" element={<Loans />} />
          <Route exact path="service" element={<Services />} />
          <Route exact path="blog" element={<Blog />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
