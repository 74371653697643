import React from "react";
// css
import "../css/style.css"
// routing
import { Link } from "react-router-dom"
// logo
import Logo from "../images/Logo.webp"
import Telephone from "../images/telephone_img.svg"
import Email from "../images/email_img.svg"

const Header = () => {
    return (
        <>
            <section className="top_header">
                {/* <div className="container-fluid py-2">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-6 col-xs-12 mobile-viewcs">
                            <div className="header_logo ">
                                <a target="_blank" href="#">
                                    <img src={Logo} width="40%" height="auto" alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="top_header_wrapper">
                                <ul>
                                    <li>
                                        <img src={Telephone} width="auto" height="auto" alt="telephone" />
                                        <a href="tel:416-621-1300">416 - 621 - 1300</a>
                                    </li>
                                    <li className="d-flex mt-2">
                                        <img src={Email} width="auto" height="auto" alt="email" />
                                        <a href="mailto:info@AKALmortgages.com">info@LAKHINAmortgages.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-0 col-xs-12 m-auto d-none d-lg-block">
                            <span style={{ display: "inline-grid" }} >
                                <div className="top_header_wrapper header_booking  ">
                                    <button className="enquier_btn">Book An Appointment</button>
                                </div>
                                <p className="lic-content">Lic 10845, Independently Owned &amp; Operated</p>
                            </span>
                        </div>
                    </div>
                </div> */}
            </section>
            <header className="header">
                <nav className="navbar navbar-expand-lg py-3 active">
                    <div className="container-fluid">
                        <a href="#" className="navbar-brand  text-uppercase font-weight-bold d-flex">
                            {/* <img src={Logo} className="navbar-logo pr-3 d-none d-lg-block" /> */}
                            <span className="m-auto" style={{fontWeight: '700', color: '#526E28'}}>LAKHINA MORTGAGES</span>
                        </a>
                        <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler navbar-toggler-right"><i className="fas fa-bars text-white"></i></button>

                        <div id="navbarSupportedContent" className="collapse navbar-collapse">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active"><Link to="/" className="nav-link ">Home <span className="sr-only">(current)</span></Link></li>
                                <li className="nav-item"><Link to="/loans" className="nav-link ">Loans</Link></li>
                                <li className="nav-item"><Link to="/service" className="nav-link ">About Us</Link></li>
                                {/* <li className="nav-item"><Link to="/blog" className="nav-link pr-4">Blog</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    )
};

export default Header;