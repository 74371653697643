import React from "react";
import { BlogData } from "./Data"
import Unname from "../images/unnamed.png"

const Blog = () => {
    return (
        <div className="container mt-5 pt-lg-5">
            <p className="pb-4 display-6">All Posts</p>
            {
                BlogData.length > 0 && BlogData.map((data, index) => {
                    return (

                        <div className="card mb-5">
                            <div className="row">
                                {/* <!-- Carousel start --> */}
                                <div className="col-md-5">
                                    <div id={`CarouselTest${index}`} className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target={`#CarouselTest${index}`} data-slide-to="0" className="active"></li>
                                            <li data-target={`#CarouselTest${index}`} data-slide-to="1"></li>
                                            <li data-target={`#CarouselTest${index}`} data-slide-to="2"></li>

                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img className="d-block" src="https://picsum.photos/450/300?image=1072" alt="" />
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block" src="https://picsum.photos/450/300?image=855" alt="" />
                                            </div>
                                            <div className="carousel-item">
                                                <img className="d-block" src="https://picsum.photos/450/300?image=355" alt="" />
                                            </div>
                                            <a className="carousel-control-prev" href={`#CarouselTest${index}`} role="button" data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                            <a className="carousel-control-next" href={`#CarouselTest${index}`} role="button" data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End of carousel --> */}

                                <div className="col-md-7 px-3">
                                    <div className="card-block px-6 px-4 pt-4">
                                        <div className="pb-3">
                                            <span className="d-flex">
                                                <img src={Unname} className="card-img-top blog-img pt-2" />
                                                <span className="pl-2 pt-0">
                                                    <small className="card-meta mb-2 text-muted">lakhinajas&nbsp;<i className="fas fa-crown"></i></small>
                                                    <p className="small text-muted">Jan 22 &nbsp;&bull;&nbsp; 1 min</p>
                                                </span>
                                            </span>
                                        </div>
                                        <h4 className="card-title font-weight-bold">{data.title}</h4>
                                        <p className="card-text text-height text-muted pb-4">
                                            {data.description}
                                        </p>
                                        <div className="card-footer bg-white pt-3 small text-muted">
                                            <span className="pr-3">2&nbsp; Views</span>
                                            <span>0&nbsp; Comments</span>
                                            <span className="float-right">0&nbsp; <i className="far fa-heart text-danger"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {/* <!-- End of card --> */}

        </div>
    )
};

export default Blog;