import React from 'react';
import bad from '../images/bad.png';
import { HomeCardData } from './Data';
import Card from './Card';
import company from '../images/company.jpg';
import ContactUs from './ContactUs';
import Lenders from './Lenders';
import LendersSmall from './LendersSmall';
import '../css/style.css'
const Home = () => {
	return (
		<>
			{/* Main Image */}
			<div className="container-fluid p-0" id="home" style={{backgroundColor: 'rgb(241, 247, 233)'}}>
				<div className="flex w-100 f-jc b-bgc">
					<img id="main-img" src={company} alt="" className="d-block" />
				</div>
			</div>

			{/* Card */}
			<div className="container mt-5 pt-lg-5">
			<h1 className= "h1-syling"  style={{color: "#526E28", fontWeight: '700', textAlign: 'center', marginBottom: '50px',textTransform: 'uppercase'}}>Personal and Mortgage Loan inquiries</h1>
				<div className="row pb-5 mb-4">
					{HomeCardData.length > 0 &&
						HomeCardData.map((data, i) => <Card key={i} onClick={data.url} data={data} />)}
				</div>
			</div>

			{/* contact us */}
			<ContactUs />

      
      <div className="show"><Lenders /></div>
			<div className="show-on-small"><LendersSmall /></div>

			{/* map */}
			<div className="container-fluid px-0 pb-5">
				<div className="mapouter">
					<div className="gmap_canvas">
						<iframe
							className="gmap_iframe"
							frameBorder="0"
							scrolling="no"
							marginHeight="0"
							marginWidth="0"
							src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=2672 Slough Street&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
						></iframe>
						<a href="https://kokagames.com/">Koka Games</a>
					</div>
				</div>
			</div>

			{/* Bottom Card */}

	
						<div
        className="container-fluid flex w-100"
        style={{ backgroundColor: "#F7F7F7", }}
      >
        <div className=" flex-1 container pb-5">
          <img
            style={{ height: "100%", width: "auto" }}
            src={bad}
            alt=""
            className='bad-image'
          />
          <div className="container text-center pb-3"></div>
        </div>
        <div className="flex-1 pt-5 plr-10 text-grey">
          <h2 className="font-weight-bold"style={{ color: "#526E28" }}>
           Finding difficuilty in getting a mortgage?
          </h2>
          <h2>
            You have a right broker with you
          </h2>
          <div>
            <ul id="contact-us-list">
              <i
                aria-hidden="true"
                style={{ color: "#004B8E" }}
                className="fas fa-check-circle"
              ></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}
              >
               Bank Declines
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"
              ></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}
              >
                Bad Credit
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"
              ></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}
              >
               High Debt Ratios
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"
              ></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}
              >
                Self Employed
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"
              ></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}
              >
               Issue with Income
              </span>
              <br />
              <i
                aria-hidden="true"
                style={{ color: "#004B8E", paddingright: "10px" }}
                className="fas fa-check-circle"
              ></i>
              <span
                className="elementor-icon-list-text"
                style={{ paddingLeft: "10px" }}
              >
                Difficuilt Situation
              </span>
            </ul>
          </div>
        </div>
      </div>
		
		</>
	);
};

export default Home;
