import React from 'react';
import '../css/horizontal-card.css';
const HorizontalCard = (props) => {
	return (
		<div className="horizontal-card">
			<div
				className={`horizontal-card-body
					${props.data.index % 2 === 0 ? 'flex-r' : 'flex-rr'}`}
			>
				<div className="flex-1 horizontal-card-left m-lr-20">
					<img alt="image" src={props.data.image} />
				</div>
				<div className="horizontal-card-right flex-1 m-lr-20">
					<h3 className='h3-loans' style={{ paddingBottom: '20px', color: '#526E28' }}>{props.data.title}</h3>
					<p className="flex">{props.data.description}</p>
				</div>
			</div>
		</div>
	);
};

export default HorizontalCard;
