import PM from "../images/heloc.width-1440-1.webp";
import Mortgage from "../images/mortgage_IH43B-1.webp";
import MG_40540 from "../images/IMG_40540-1.webp";
import TD from "../images/TD.png";
import Scotia from "../images/Scotia.png";
import Manulife from "../images/Manulife.png";
import Icici from "../images/Icici.png";
import HomeTrust from "../images/HomeTrust.png";
import FirstNational from "../images/FirstNational.png";

import LoanImage1 from "../images/loanPage/1.jpg";
import LoanImage2 from "../images/loanPage/2.jpg";
import LoanImage3 from "../images/loanPage/3.jpg";
import LoanImage4 from "../images/loanPage/4.jpg";
import LoanImage5 from "../images/loanPage/5.jpg";
import LoanImage6 from "../images/loanPage/6.jpg";
import LoanImage7 from "../images/loanPage/7.jpg";
import LoanImage9 from "../images/loanPage/9.jpg";
import LoanImage8 from "../images/loanPage/8.jpg";
import LoanImage10 from "../images/loanPage/10.jpg";
import LoanImage12 from "../images/loanPage/12.jpg";

import LoanImage14 from "../images/loanPage/14.jpg";
import LoanImage11 from "../images/loanPage/11.jpg";
import LoanImage16 from "../images/loanPage/16.jpg";
import LoanImage19 from "../images/loanPage/19.jpg";
export const HomeCardData = [
  {
    image: PM,
    text: "Send us email and we will get you the response you need as fast as we can",
    btnText: "Email Us",
    href: "mailto:lakhina.jas@gmail.com",
  },
  {
    image: Mortgage,
    text: "Connect with one of our representatives to help answer your questions",
    btnText: "Contact Us",
    href: "tel:+1 647-309-4608",
  },
  {
    image: MG_40540,
    text: "We provide different type of loans, Please contact us to find suitable one.",
    btnText: "+1 647-309-4608",
    href: "tel:+1 647-309-4608",
  },
];

export const QuoteSliderData = [
  [TD, Scotia, Manulife, Icici],
  [Manulife, HomeTrust, FirstNational, Manulife],
  [TD, Scotia, HomeTrust, Icici],
];

export const QuoteSliderDataForSmall = [
  TD, Scotia, Manulife, Icici, HomeTrust, FirstNational,
];

export const BlogData = [
  {
    title: "5 up-and-coming neighborhoods",
    description:
      "To create and manage your own content, open the Blog Manager by hovering over your blog feed and clicking Manage. Here you can create,...",
  },
  {
    title: "Welcome to your new property: next steps",
    description:
      "To edit the way your blog feed looks on your site, hover over your blog feed and click on Design. Here, you can pick from different...",
  },
  {
    title: "Common property contract pitfalls to avoid",
    description:
      "Edit exactly how your blog looks on your website from the Settings panel. Wix Blogs lets you hide or display the author name and picture,...",
  },
];

export const LoadPageData = [
  {
    image: LoanImage1,
    title: "Homebuyer 95 Program",
    description:
      "Enable qualified borrowers to become first-time homeowners with as little as a 5% down payment.",
  },
  {
    image: LoanImage2,
    title: "New to Canada Program",
    description:
      "Help individuals who have recently immigrated or relocated to Canada to purchase property with as little as 5% down.",
  },
  {
    image: LoanImage3,
    title: "Investment Property Program",
    description:
      "Provide qualified borrowers with the opportunity to purchase an additional investment property with as little as 20% down.",
  },
  {
    image: LoanImage4,
    title: "Family Program Plan",
    description:
      "Help customers buy a home for immediate family with good credit who donnot meet standard GDSR/TDSR requirements.",
  },
  {
    image: LoanImage14,
    title: "Vacation/Secondary Home",
    description:
      "Enable customers to purchase a second homes at an affordable monthly payment with just 5% down",
  },
  {
    image: LoanImage6,
    title: "Self-Directed RRSP",
    description:
      "Provide the insurnace to allow self-directed RRSP/RRIF funds to be used for non-arm's length mortgage investments.",
  },
  {
    image: LoanImage12,
    title: "Borrowed Down Payment",
    description:
      "Make it possible for borrowers who cannot save a required down payment to start building equity now.",
  },
  {
    image: LoanImage16,
    title: "Second Mortgage Program",
    description:
      "Provide borrowers with a Second Mortgage to be registered against their property up to 95% combined LTV on a purchase.",
  },
  {
    image: LoanImage11,
    title: "Portability Program",
    description:
      "Help insured borrowers to save on the costs of a new mortgage by porting their default insurance.",
  },
  {
    image: LoanImage12,
    title: "Energy Efficient Housing Program",
    description:
      "Reward home buyers purchasing energy efficient homes or making energy saving renovations with significant savings.",
  },
  {
    image: LoanImage19,
    title: "Purchase Plus Program",
    description:
      "Let residential home builders and individual home buyers take advantage of insured progress advances while their property is being built.",
  },
];
