import React from 'react';

const Card = (props) => {
	return (
		<div className="card shadow-sm border-0">
			<div className="card-body">
				<div className="image">
					<img src={props.data.image} alt="" className="w-100" />
				</div>
				<div className="p-4">
					<p className="medium text-dark" style={{ textAlign: 'center' }}>
						{props.data.text}
					</p>
					<div className="flex f-jc">
						<a href={props.data.href} className="btn-book">{props.data.btnText}</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;
