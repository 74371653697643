import React from "react";
import { QuoteSliderDataForSmall } from "./Data";

const LendersSmall = () => {
  return (
    <div
      className="w-100"
      style={{
        backgroundColor: "#F7F7F7",
        paddingBottom: "50px",
        paddingTop: "50px"
      }}>
      <h3
        style={{
          color: "#526E28",
          textAlign: "center",
          marginBottom: "30px",
          textTransform: "uppercase",
          fontWeight: "700"
        }}>
        Our Lenders
      </h3>
      <div className="row pb-5 w-100">
        <div style={{ margin: "auto" }}>
          <div
            className="carousel slide"
            id="carouselExampleIndicators"
            data-ride="carousel">
            <ol
              className="carousel-indicators mb-0"
              style={{ marginBottom: "-29px!important" }}>
              {QuoteSliderDataForSmall.map((lender, i) => {
                return (
                  <li
                    key={i}
                    className={i === 0 ? "active" : ""}
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"></li>
                );
              })}
            </ol>

            <div className="carousel-inner px-5 pb-5">
              {QuoteSliderDataForSmall.length > 0 &&
                QuoteSliderDataForSmall.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className={`carousel-item ${index === 0 && "active"}`}>
                      <div className="media ">
                        <div className="media-body ml-3">
                          <img src={data} />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LendersSmall;
