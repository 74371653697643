import React from "react";
import "../css/style.css";
import { LoadPageData } from "./Data";
import HorizontalCard from "./HorizontalCard";

const Loans = () => {
  return (
    <div
      className="container pt-lg-5"
      style={{
        backgroundColor: "#f1f7e9"
      }}>
      <h1 className="text-center pb-5" style={{ fontWeight: "700" }}>
        Lakhina Mortgages Products
      </h1>
      <p className="loans-para mb-5">
        Our products help lenders like offer mortgages to more Canadian than
        ever, from low-income families to new immigrants. Learn more about each
        option below.
      </p>

      {LoadPageData.length > 0 &&
        LoadPageData.map((data, index) => (
          <HorizontalCard key={index.toString()} data={{ ...data, index }} />
        ))}
      <div
        className="flex f-jc"
        style={{
          fontWeight: "800",
          textTransform: "uppercase",
          paddingBottom: "50px"
        }}>
        <a
          className="btn-book"
          style={{
            backgroundColor: "#526E28",
            color: "white",
            fontWeight: "600",
            textTransform: "uppercase"
          }}
          href="tel:+1 647-309-4608">
          Call Us
        </a>
      </div>
    </div>
  );
};

export default Loans;
